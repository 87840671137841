import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../firebase";
import Preloader from "../components/Preloader";

const Deposit = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<any>({});
  const [asset, setAsset] = useState("BTC");
  const [amount, setAmount] = useState("");
  const [plan, setPlan] = useState("Free");

  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const inputRef: any = useRef(null);
  const [firstName] = user?.name.split(" ");

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
    } else {
      setSelectedFile(null);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  useEffect(() => {
    if (+amount >= 100 && +amount <= 4999) {
      setPlan("Starter");
    } else if (+amount >= 5000 && +amount <= 49999) {
      setPlan("Standard");
    } else if (+amount >= 50000 && +amount <= 59999) {
      setPlan("Investor");
    } else if (+amount >= 60000) {
      setPlan("Platinum");
    } else {
      setPlan("Free");
    }
  }, [amount]);

  useEffect(() => {
    if (+amount && +amount > 100 && fileUploaded) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [amount, fileUploaded]);

  useEffect(() => {
    const getPaymentDetails = async () => {
      const { data } = await axios.get(
        "https://api.istratradesphere.com/api/payment-details"
      );

      setPaymentDetails(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getPaymentDetails();
  }, []);

  const deposit = async () => {
    const token = localStorage.getItem("istra");

    try {
      setLoading(true);

      const fileName = new Date().getTime() + selectedFile.name;
      const storage = getStorage(app);
      const storageRef = ref(storage, fileName);
      const uploadTask = uploadBytesResumable(storageRef, selectedFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          setLoading(false);
          toast.error("Something went wrong");
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const confirmDeposit = async () => {
              await axios.post(
                `https://api.istratradesphere.com/api/transaction/${user?._id}`,
                {
                  user,
                  amount: +amount,
                  transactionType: "Deposit",
                  asset,
                  transactionId: new Date().getTime().toString(),
                  dateTime: new Date(),
                  proof: downloadURL,
                  remarks,
                },
                {
                  headers: { token: `Bearer ${token}` },
                }
              );
            };

            confirmDeposit();
            setLoading(false);
            setReviewModalOpen(true);
          });
        }
      );
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="main-content col-lg-8">
              <div className="content-area card">
                <div className="card-innr">
                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 1
                    </span>
                    <h4 className="card-title">
                      Choose crypto asset to deposit
                    </h4>
                  </div>
                  <div className="card-text">
                    <p>
                      You can deposit funds for your investment using BTC, ETH
                      or USDT.
                    </p>
                  </div>
                  <div className="token-currency-choose">
                    <div className="row guttar-15px">
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="paybtc"
                            name="payOption"
                            defaultChecked
                            value="BTC"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="paybtc">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-btc" />
                              <span className="pay-cur">BTC</span>
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="payeth"
                            name="payOption"
                            value="ETH"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="payeth">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-eth" />
                              <span className="pay-cur">ETH</span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="pay-option">
                          <input
                            className="pay-option-check"
                            type="radio"
                            id="payusdt"
                            name="payOption"
                            value="USDT"
                            onChange={(e) => setAsset(e.target.value)}
                          />
                          <label className="pay-option-label" htmlFor="payusdt">
                            <span className="pay-title">
                              <em className="pay-icon cf cf-usdt" />
                              <span className="pay-cur">USDT</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 2
                    </span>
                    <h4 className="card-title">Enter deposit amount</h4>
                  </div>
                  <div className="card-text">
                    <p className="mb-2">
                      Enter your deposit amount according to your desired plan
                    </p>
                  </div>
                  <div className="token-calc-note note note-plane">
                    <em className="fas fa-info-circle text-info" />
                    <span className="note-text text-light">
                      Starter Plan: $500 minimum
                    </span>
                  </div>
                  <div className="token-calc-note note note-plane">
                    <em className="fas fa-info-circle text-info" />
                    <span className="note-text text-light">
                      Standard Plan: $5,000 minimum
                    </span>
                  </div>
                  <div className="token-calc-note note note-plane">
                    <em className="fas fa-info-circle text-info" />
                    <span className="note-text text-light">
                      Investor Plan: $50,000 minimum
                    </span>
                  </div>
                  <div className="token-calc-note note note-plane">
                    <em className="fas fa-info-circle text-info" />
                    <span className="note-text text-light">
                      Platinum Plan: 1BTC minimum
                    </span>
                  </div>
                  <div className="token-contribute">
                    <div className="token-calc">
                      <div className="token-pay-amount">
                        <input
                          id="token-base-amount"
                          className="input-bordered input-with-hint"
                          type="number"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <div className="token-received">
                        <div className="token-eq-sign">=</div>
                        <div className="token-received-amount">
                          <h5 className="token-amount">{plan}</h5>
                          <div className="token-symbol">Plan</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head">
                    <span className="card-sub-title text-primary font-mid">
                      Step 3
                    </span>
                    <h4 className="card-title">Make payment</h4>
                  </div>
                  <div className="card-text">
                    <p>
                      Simply copy the wallet address of your chosen crypto asset
                      and send the deposit amount
                    </p>
                  </div>
                  <div className="pay-buttons">
                    <div className="pay-button">
                      <a
                        href="#/"
                        onClick={() => setWalletModalOpen(true)}
                        data-toggle="modal"
                        data-target="#get-pay-address"
                        className="btn btn-light-alt btn-between w-100"
                      >
                        Get Address for Payment <em className="ti ti-wallet" />
                      </a>
                    </div>
                  </div>

                  <div className="pay-notes" />

                  <div className="card-head mt-8">
                    <span className="card-sub-title text-primary font-mid">
                      Step 4
                    </span>
                    <h4 className="card-title">Show Proof of Payment</h4>
                  </div>
                  <div className="card-text">
                    <p>
                      To speed up confirmation of your deposit, we require that
                      you send in proof of your deposit. This can be in the form
                      of a picture/snapshot of your receipt or any other
                      necessary file or a written note under the Remarks
                      section.
                    </p>
                  </div>
                  <div className="row align-items-center mt-4">
                    <div className="col-sm-8">
                      <div className="upload-box" onDragEnter={handleDrag}>
                        <div className="upload-zone dropzone dz-clickable">
                          <input
                            ref={inputRef}
                            type="file"
                            id="input-file-upload"
                            style={{ display: "none" }}
                            onChange={handleChange}
                            accept="image/*"
                          />
                          {fileUploaded ? (
                            <div className="dz-message" data-dz-message="true">
                              <label htmlFor="input-file-upload">
                                <span
                                  className="dz-message-text mb-3 text-lg"
                                  style={{ color: "#007db6" }}
                                >
                                  {selectedFile.name.length > 20
                                    ? `${selectedFile?.name.substring(
                                        0,
                                        20
                                      )}...`
                                    : selectedFile?.name}
                                </span>
                                <button
                                  className="btn btn-primary bg-green"
                                  type="button"
                                  onClick={cancelUpload}
                                >
                                  CANCEL
                                </button>
                              </label>
                            </div>
                          ) : (
                            <div className="dz-message" data-dz-message="true">
                              <label htmlFor="input-file-upload">
                                <span className="dz-message-text">
                                  Drag and drop file
                                </span>
                                <span className="dz-message-or">or</span>
                                <button
                                  className="btn btn-primary bg-green"
                                  type="button"
                                  onClick={onButtonClick}
                                >
                                  SELECT
                                </button>
                              </label>
                            </div>
                          )}
                        </div>
                        {dragActive && (
                          <div
                            className="absolute w-full h-full border-[1rem] top-0 bottom-0 right-0 left-0"
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </div>
                      <div className="note note-plane note-light note-md font-italic mt-3">
                        <em className="fas fa-info-circle" />
                        <p>Uploaded file should be 4MB or less.</p>
                      </div>
                    </div>
                  </div>
                  <div className="input-item input-with-label mt-3">
                    <label htmlFor="remarks" className="input-item-label">
                      Remarks (Optional):
                    </label>
                    <textarea
                      className="input-bordered"
                      id="remarks"
                      rows={4}
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  </div>
                  {formCompleted && (
                    <>
                      {loading ? (
                        <button className="btn btn-primary" disabled>
                          Confirming Payment...
                        </button>
                      ) : (
                        <button className="btn btn-primary" onClick={deposit}>
                          Confirm Payment
                        </button>
                      )}
                    </>
                  )}
                </div>{" "}
              </div>{" "}
            </div>

            <div className="aside sidebar-right col-lg-4">
              <div className="token-statistics card card-token height-auto">
                <div className="card-innr">
                  <div className="token-balance token-balance-with-icon">
                    <div className="token-balance-text">
                      <h6 className="card-sub-title">Balance</h6>
                      <span className="lead">
                        <span>$</span>
                        {user?.balance?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul
                      className="token-balance-list"
                      style={{ justifyContent: "space-between" }}
                    >
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.deposit?.toLocaleString()}
                        </span>
                        <span className="sub">DEPOSIT</span>
                      </li>
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.revenue?.toLocaleString()}
                        </span>
                        <span className="sub">REVENUE</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="referral-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Earn with Referral
                  </h6>
                  <p className=" pdb-0-5x">
                    Invite your friends &amp; family and receive a{" "}
                    <strong>
                      <span className="text-primary">bonus</span> of the value
                      of their investment.
                    </strong>
                  </p>
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={`https://dashboard.istratradesphere.com/register?ref=${firstName}${user?._id}`}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://dashboard.istratradesphere.com/register?ref=${firstName}${user?._id}`
                        );
                        toast.success("Referral link copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                </div>
              </div>
              {user.kycStatus !== "Approved" && (
                <div className="kyc-info card">
                  <div className="card-innr">
                    <h6 className="card-title card-title-sm">
                      Identity Verification - KYC
                    </h6>
                    <p>
                      To comply with regulation, investors will have to go
                      through identity verification.
                    </p>
                    <p className="lead text-light pdb-0-5x">
                      You have not submitted your KYC application to verify your
                      identity.
                    </p>
                    <Link
                      to="/kyc-application"
                      className="btn btn-primary btn-block"
                    >
                      Click to Proceed
                    </Link>
                    <h6 className="kyc-alert text-danger">
                      * KYC verification required for certain actions
                    </h6>
                  </div>
                </div>
              )}
            </div>
            {/* .col */}
          </div>
          {/* .container */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      {walletModalOpen && <div className="modal-backdrop fade show" />}
      {walletModalOpen && (
        <div
          className="modal fade show"
          id="get-pay-address"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <a
                href="#/"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setWalletModalOpen(false)}
              >
                <em className="ti ti-close" />
              </a>
              <div className="popup-body">
                <h4 className="popup-title">Payment Address for Deposit</h4>
                <p>
                  Please make deposit of <strong>${+amount}</strong> to this{" "}
                  {asset} address
                </p>
                <div className="gaps-1x" />
                <h6 className="font-bold">
                  Payment to the following {asset} address:
                </h6>
                {asset === "BTC" && (
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={paymentDetails.btcAddress}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          paymentDetails.btcAddress
                        );
                        toast.success("BTC address copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                )}

                {asset === "ETH" && (
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={paymentDetails.ethAddress}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          paymentDetails.ethAddress
                        );
                        toast.success("ETH address copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                )}

                {asset === "USDT" && (
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={paymentDetails.usdtAddress}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          paymentDetails.usdtAddress
                        );
                        toast.success("USDT address copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                    <p style={{ marginTop: "10px" }}>
                      Network: {paymentDetails.usdtNetwork}
                    </p>
                  </div>
                )}

                <div className="gaps-3x" />

                <div className="note note-plane note-info">
                  <em className="fas fa-info-circle" />
                  <p>
                    Please ensure deposit amount matches amount sent to this
                    address.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {reviewModalOpen && <div className="modal-backdrop fade show" />}
      {reviewModalOpen && (
        <div
          className="modal fade show"
          id="pay-review"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="pay-status pay-status-success">
                  <em className="ti ti-check" />
                </div>
                <div className="gaps-2x" />
                <h3>We’re reviewing your payment</h3>
                <p>
                  We’ll review your transaction and get back to you as soon as
                  possible.
                </p>
                <div className="gaps-2x" />
                <button
                  className="btn btn-primary bg-green"
                  type="button"
                  onClick={() => {
                    setReviewModalOpen(false);
                    navigate("/transactions");
                  }}
                >
                  Close
                </button>
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Deposit;
