import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import Preloader from "../components/Preloader";
import axios from "axios";
import logo from "../assets/favicon.png";

const Home = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [investmentProgress, setInvestmentProgress] = useState("0%");
  const [milestone, setMilestone] = useState(100000);

  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getUserTransactions = async () => {
      const { data } = await axios.get(
        `https://api.istratradesphere.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUserTransactions();
  }, [user?._id]);

  useEffect(() => {
    if (user?.revenue > 100000) {
      setMilestone(1000000);
    }
  }, [user?.revenue]);

  useEffect(() => {
    const calculateInvestmentProgress = () => {
      const prog = `${(user?.revenue / milestone) * 100}%`;
      setInvestmentProgress(prog);
    };

    calculateInvestmentProgress();
  }, [milestone, user?.revenue]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      defaultColumn: "overview",
      screener_type: "crypto_mkt",
      displayCurrency: "USD",
      colorTheme: "light",
      locale: "en",
    });
    document?.getElementById("myContainer")?.appendChild(script);
  }, []);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="token-statistics card card-token height-auto">
                <div className="card-innr">
                  <div className="token-balance token-balance-with-icon">
                    <div className="token-balance-text">
                      <h6 className="card-sub-title">Balance</h6>
                      <span className="lead">
                        <span>$</span>
                        {user?.balance?.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul
                      className="token-balance-list"
                      style={{ justifyContent: "space-between" }}
                    >
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.deposit?.toLocaleString()}
                        </span>
                        <span className="sub">DEPOSIT</span>
                      </li>
                      <li className="token-balance-sub">
                        <span className="lead">
                          ${user?.revenue?.toLocaleString()}
                        </span>
                        <span className="sub">REVENUE</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* .col */}
            <div className="col-lg-8">
              <div className="token-information card card-full-height">
                <div className="row no-gutters height-100">
                  <div className="col-md-6 text-center">
                    <div className="token-info">
                      <img
                        className="token-info-icon"
                        src={logo}
                        alt="logo-sm"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="token-info bdr-tl">
                      <div>
                        <ul className="token-info-list">
                          {user?.deposit <= 99 && (
                            <li>
                              <span>Your Plan:</span> Free
                            </li>
                          )}
                          {user?.deposit >= 100 && user?.deposit <= 50000 && (
                            <li>
                              <span>Your Plan:</span> Golden
                            </li>
                          )}
                          {user?.deposit >= 50001 &&
                            user?.deposit <= 100000 && (
                              <li>
                                <span>Your Plan:</span> Sterling
                              </li>
                            )}
                          {user?.deposit >= 100001 &&
                            user?.deposit <= 1000000 && (
                              <li>
                                <span>Your Plan:</span> Premium
                              </li>
                            )}
                          {user?.deposit >= 1000001 && (
                            <li>
                              <span>Your Plan:</span> Platinum
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .card */}
            </div>
            {/* .col */}
            <div className="col-xl-8 col-lg-7">
              <div className="token-transaction card card-full-height">
                <div className="card-innr">
                  <div className="card-head has-aside">
                    <h4 className="card-title">Transactions</h4>
                    <div className="card-opt">
                      <Link to="/transactions" className="link ucap">
                        View ALL <em className="fas fa-angle-right ml-2" />
                      </Link>
                    </div>
                  </div>
                  {transactions?.length > 0 ? (
                    <table className="table tnx-table">
                      <thead>
                        <tr>
                          <th>Transaction Details</th>
                          <th>Amount</th>
                          <th className="d-none d-sm-table-cell tnx-date">
                            Date
                          </th>
                          <th className="tnx-type">
                            <div className="tnx-type-text" />
                          </th>
                        </tr>
                        {/* tr */}
                      </thead>
                      {/* thead */}
                      <tbody>
                        {transactions?.slice(0, 3)?.map((transaction: any) => (
                          <tr key={transaction._id}>
                            <td>
                              <div className="d-flex align-items-center">
                                {transaction.status === "Rejected" && (
                                  <div className="data-state data-state-canceled" />
                                )}
                                {transaction.status === "Pending" && (
                                  <div className="data-state data-state-progress" />
                                )}
                                {transaction.status === "Confirmed" && (
                                  <div className="data-state data-state-approved" />
                                )}
                                <span className="lead">
                                  #{transaction.transactionId}
                                </span>
                              </div>
                            </td>
                            <td>
                              <span>
                                <span className="lead">
                                  ${transaction?.amount?.toLocaleString()}
                                </span>
                              </span>
                            </td>
                            <td className="d-none d-sm-table-cell tnx-date">
                              <span className="sub sub-s2">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </span>
                            </td>
                            {transaction.transactionType === "Deposit" && (
                              <td className="tnx-type">
                                <span
                                  className="tnx-type-md badge badge-outline badge-success badge-md"
                                  style={{ borderColor: "blue" }}
                                >
                                  Deposit
                                </span>
                                <span
                                  className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md"
                                  style={{ borderColor: "blue" }}
                                >
                                  D
                                </span>
                              </td>
                            )}
                            {transaction.transactionType === "Withdrawal" && (
                              <td className="tnx-type">
                                <span
                                  className="tnx-type-md badge badge-outline badge-success badge-md"
                                  style={{ borderColor: "orange" }}
                                >
                                  Withdrawal
                                </span>
                                <span
                                  className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md"
                                  style={{ borderColor: "orange" }}
                                >
                                  W
                                </span>
                              </td>
                            )}
                            {transaction.transactionType === "Credited" && (
                              <td className="tnx-type">
                                <span className="tnx-type-md badge badge-outline badge-success badge-md">
                                  Credited
                                </span>
                                <span className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md">
                                  C
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                      {/* tbody */}
                    </table>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      No transactions yet
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="token-sales card card-full-height">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Investment Progress</h4>
                  </div>
                  <ul className="progress-info">
                    <li>
                      <span>Earned</span> ${user?.revenue?.toLocaleString()}
                    </li>
                    <li className="text-right">
                      <span>GOAL</span>{" "}
                      {user?.revenue <= 100000 ? "$100,000" : "$1,000,000"}
                    </li>
                  </ul>
                  <div className="progress-bar">
                    <div
                      className="progress-hcap"
                      style={{ width: "100%" }}
                    ></div>
                    <div
                      className="progress-percent"
                      style={{ width: investmentProgress }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* .row */}
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="token-sale-graph card card-full-height">
                <div className="card-innr">
                  <div
                    className="chart-tokensale"
                    id="myContainer"
                    style={{ height: "250px" }}
                  >
                    <div className="tradingview-widget-container">
                      <div className="tradingview-widget-container__widget"></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* .card */}
            </div>
          </div>
          {/* .row */}
        </div>
        {/* .container */}
      </div>

      <Footer />
    </div>
  );
};

export default Home;
