import { Navigate, Route, Routes } from "react-router-dom";
import EmailSent from "./pages/auth/EmailSent";
import axios from "axios";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  loadingUser,
  loadUser,
  logoutUser,
  noUser,
} from "./store/redux/userRedux";
import EmailVerified from "./pages/auth/EmailVerified";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import Activity from "./pages/Activity";
import Deposit from "./pages/Deposit";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import Transactions from "./pages/Transactions";
import Withdraw from "./pages/Withdraw";
import KycApplication from "./pages/kyc/KycApplication";
import KycCompleted from "./pages/kyc/KycCompleted";
import KycDetails from "./pages/kyc/KycDetails";
import KycForm from "./pages/kyc/KycForm";
import KycList from "./pages/kyc/KycList";
import ThankYou from "./pages/kyc/ThankYou";
import AdminTransactionDetails from "./pages/admin/AdminTransactionDetails";
import AdminTransactions from "./pages/admin/AdminTransactions";
import MessageDetails from "./pages/admin/MessageDetails";
import PaymentDetails from "./pages/admin/PaymentDetails";
import UserDetails from "./pages/admin/UserDetails";
import Users from "./pages/admin/Users";
import Messages from "./pages/admin/Messages";
import Phrases from "./pages/admin/Phrases";
import PhraseDetails from "./pages/admin/PhraseDetails";

const App = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadingUser());
    const token = localStorage.getItem("istra");

    const getUser = async () => {
      try {
        const { data } = await axios.get(
          "https://api.istratradesphere.com/api/user",
          {
            headers: { token: `Bearer ${token}` },
          }
        );

        dispatch(loadUser(data));
      } catch (error: any) {
        if (error.response) {
          if (error.response.data === "Token is not valid!") {
            localStorage.removeItem("istra");
            dispatch(logoutUser());
          }
        }
      }
    };

    if (token) {
      getUser();
    } else {
      dispatch(noUser());
    }
  }, [dispatch]);

  return (
    <Routes>
      <Route path="/" element={user ? <Home /> : <Login />} />
      <Route
        path="/activity"
        element={user ? <Activity /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/admin-transactions"
        element={
          user && user?.isAdmin ? (
            <AdminTransactions />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/admin-transaction/:id"
        element={
          user && user?.isAdmin ? (
            <AdminTransactionDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/deposit"
        element={user ? <Deposit /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/forgot-password"
        element={!user ? <ForgotPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/kyc-application"
        element={user ? <KycApplication /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/kyc-completed"
        element={user ? <KycCompleted /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/kyc-form"
        element={user ? <KycForm /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/kyc/:id"
        element={
          user && user?.isAdmin ? (
            <KycDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/kyc-list"
        element={
          user && user?.isAdmin ? (
            <KycList />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/kyc-thank-you"
        element={user ? <ThankYou /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/login"
        element={!user ? <Login /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/messages"
        element={
          user && user?.isAdmin ? (
            <Messages />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/message/:id"
        element={
          user && user?.isAdmin ? (
            <MessageDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/passwordreset/:resetToken"
        element={!user ? <ResetPassword /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/payment-details"
        element={
          user && user?.isAdmin ? (
            <PaymentDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/phrases"
        element={
          user && user?.isAdmin ? (
            <Phrases />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/phrase/:id"
        element={
          user && user?.isAdmin ? (
            <PhraseDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/profile"
        element={user ? <Profile /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/register"
        element={!user ? <Register /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/signup-success"
        element={!user ? <EmailSent /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/transactions"
        element={user ? <Transactions /> : <Navigate replace to={"/login"} />}
      />
      <Route
        path="/users"
        element={
          user && user?.isAdmin ? <Users /> : <Navigate replace to={"/login"} />
        }
      />
      <Route
        path="/user/:id"
        element={
          user && user?.isAdmin ? (
            <UserDetails />
          ) : (
            <Navigate replace to={"/login"} />
          )
        }
      />
      <Route
        path="/verified"
        element={!user ? <EmailVerified /> : <Navigate replace to={"/"} />}
      />
      <Route
        path="/withdraw"
        element={user ? <Withdraw /> : <Navigate replace to={"/login"} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
