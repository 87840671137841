import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TopBar from "../components/TopBar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import axios from "axios";
import Pagination from "../components/Pagination";

const Transactions = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [tabSelected, setTabSelected] = useState("All");
  const [selectedTransaction, setSelectedTransaction] = useState<any>({});
  const [transactionDetailsModalOpen, setTransactionDetailsModalOpen] =
    useState(false);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = transactions?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(transactions?.length / recordsPerPage);

  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getUserTransactions = async () => {
      const { data } = await axios.get(
        `https://api.istratradesphere.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUserTransactions();
  }, [user?._id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">User Transactions</h4>
              </div>
              <div>
                <div className="gaps-1x" />
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "All" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => setTabSelected("All")}
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Deposit"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Deposit")}
                    >
                      Deposit
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Withdrawal"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Withdrawal")}
                    >
                      Withdrawal
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#/"
                      className={
                        tabSelected === "Credited"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={() => setTabSelected("Credited")}
                    >
                      Credited
                    </a>
                  </li>
                </ul>
                <div className="gaps-1x" />
              </div>
              {tabSelected === "All" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords?.map((transaction: any) => (
                      <tr className="data-item" key={transaction._id}>
                        <td className="data-col dt-tnxno">
                          <div className="d-flex align-items-center">
                            {transaction.status === "Pending" && (
                              <div className="data-state data-state-progress">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Rejected" && (
                              <div className="data-state data-state-canceled">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            {transaction.status === "Confirmed" && (
                              <div className="data-state data-state-approved">
                                <span className="d-none">
                                  {transaction.status}
                                </span>
                              </div>
                            )}
                            <div className="fake-class">
                              <span className="lead tnx-id">
                                #{transaction.transactionId}
                              </span>
                              <span className="sub sub-date">
                                {new Date(
                                  transaction.createdAt
                                ).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className="data-col dt-token">
                          <span className="lead token-amount">
                            {transaction?.amount?.toLocaleString()}
                          </span>
                          <span className="sub sub-symbol">USD</span>
                        </td>
                        <td className="data-col dt-amount">
                          {transaction.asset ? (
                            <span className="lead amount-pay">
                              {transaction.asset}
                            </span>
                          ) : (
                            <span className="lead amount-pay">-</span>
                          )}
                        </td>

                        {transaction.transactionType === "Deposit" && (
                          <td className="data-col dt-type">
                            <span
                              className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                              style={{ borderColor: "blue" }}
                            >
                              Deposit
                            </span>
                            <span
                              className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                              style={{ borderColor: "blue" }}
                            >
                              D
                            </span>
                          </td>
                        )}
                        {transaction.transactionType === "Withdrawal" && (
                          <td className="data-col dt-type">
                            <span
                              className="dt-type-md badge badge-outline badge-success badge-md"
                              style={{ borderColor: "orange" }}
                            >
                              Withdrawal
                            </span>
                            <span
                              className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                              style={{ borderColor: "orange" }}
                            >
                              W
                            </span>
                          </td>
                        )}
                        {transaction.transactionType === "Credited" && (
                          <td className="data-col dt-type">
                            <span className="dt-type-md badge badge-outline badge-success badge-md">
                              Credited
                            </span>
                            <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                              C
                            </span>
                          </td>
                        )}
                        <td className="data-col text-right">
                          <a
                            href="#/"
                            data-toggle="modal"
                            data-target="#transaction-details"
                            className="btn btn-light-alt btn-xs btn-icon"
                            onClick={() => {
                              setSelectedTransaction(transaction);
                              setTransactionDetailsModalOpen(true);
                            }}
                          >
                            <em className="ti ti-eye" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {tabSelected === "All" && transactions?.length === 0 && (
                <div style={{ height: "472px" }}>
                  <p
                    className="text-xl  translate-y-[-50%]"
                    style={{
                      textAlign: "center",
                      position: "relative",
                      top: "50%",
                    }}
                  >
                    No transactions yet
                  </p>
                </div>
              )}
              {tabSelected === "All" && transactions?.length > 10 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}

              {tabSelected === "Deposit" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Deposit"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>

                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <a
                              href="#/"
                              data-toggle="modal"
                              data-target="#transaction-details"
                              className="btn btn-light-alt btn-xs btn-icon"
                              onClick={() => {
                                setSelectedTransaction(transaction);
                                setTransactionDetailsModalOpen(true);
                              }}
                            >
                              <em className="ti ti-eye" />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              {tabSelected === "Withdrawal" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Withdrawal"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>

                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <a
                              href="#/"
                              data-toggle="modal"
                              data-target="#transaction-details"
                              className="btn btn-light-alt btn-xs btn-icon"
                              onClick={() => {
                                setSelectedTransaction(transaction);
                                setTransactionDetailsModalOpen(true);
                              }}
                            >
                              <em className="ti ti-eye" />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}

              {tabSelected === "Credited" && transactions?.length > 0 && (
                <table className="data-table dt-init user-tnx">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-tnxno">Transaction Details</th>
                      <th className="data-col dt-amount">Amount</th>
                      <th className="data-col dt-usd-amount">Asset</th>
                      <th className="data-col dt-type">
                        <div className="dt-type-text">Type</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords
                      ?.filter(
                        (trnx: any) => trnx.transactionType === "Credited"
                      )
                      ?.map((transaction: any) => (
                        <tr className="data-item" key={transaction._id}>
                          <td className="data-col dt-tnxno">
                            <div className="d-flex align-items-center">
                              {transaction.status === "Pending" && (
                                <div className="data-state data-state-progress">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Rejected" && (
                                <div className="data-state data-state-canceled">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              {transaction.status === "Confirmed" && (
                                <div className="data-state data-state-approved">
                                  <span className="d-none">
                                    {transaction.status}
                                  </span>
                                </div>
                              )}
                              <div className="fake-class">
                                <span className="lead tnx-id">
                                  #{transaction.transactionId}
                                </span>
                                <span className="sub sub-date">
                                  {new Date(
                                    transaction.createdAt
                                  ).toLocaleString()}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="data-col dt-token">
                            <span className="lead token-amount">
                              {transaction?.amount?.toLocaleString()}
                            </span>
                            <span className="sub sub-symbol">USD</span>
                          </td>
                          <td className="data-col dt-amount">
                            {transaction.asset ? (
                              <span className="lead amount-pay">
                                {transaction.asset}
                              </span>
                            ) : (
                              <span className="lead amount-pay">-</span>
                            )}
                          </td>

                          {transaction.transactionType === "Deposit" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md border-[blue]"
                                style={{ borderColor: "blue" }}
                              >
                                Deposit
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "blue" }}
                              >
                                D
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Withdrawal" && (
                            <td className="data-col dt-type">
                              <span
                                className="dt-type-md badge badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                Withdrawal
                              </span>
                              <span
                                className="dt-type-sm badge badge-sq badge-outline badge-success badge-md"
                                style={{ borderColor: "orange" }}
                              >
                                W
                              </span>
                            </td>
                          )}
                          {transaction.transactionType === "Credited" && (
                            <td className="data-col dt-type">
                              <span className="dt-type-md badge badge-outline badge-success badge-md">
                                Credited
                              </span>
                              <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">
                                C
                              </span>
                            </td>
                          )}
                          <td className="data-col text-right">
                            <a
                              href="#/"
                              data-toggle="modal"
                              data-target="#transaction-details"
                              className="btn btn-light-alt btn-xs btn-icon"
                              onClick={() => {
                                setSelectedTransaction(transaction);
                                setTransactionDetailsModalOpen(true);
                              }}
                            >
                              <em className="ti ti-eye" />
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
            {/* .card-innr */}
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      {transactionDetailsModalOpen && (
        <div className="modal-backdrop fade show" />
      )}
      {transactionDetailsModalOpen && (
        <div
          className="modal fade show"
          id="transaction-details"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
            <div className="modal-content">
              <a
                href="#/"
                className="modal-close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setTransactionDetailsModalOpen(false)}
              >
                <em className="ti ti-close" />
              </a>
              <div className="popup-body popup-body-lg">
                <div className="content-area">
                  <div className="card-head d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">Transaction Details</h4>
                  </div>
                  <div className="gaps-1-5x" />
                  <div className="data-details d-md-flex">
                    <div className="fake-class">
                      <span className="data-details-title">
                        Transaction Date
                      </span>
                      <span className="data-details-info">
                        {new Date(
                          selectedTransaction?.createdAt
                        ).toLocaleString()}
                      </span>
                    </div>
                    <div className="fake-class">
                      <span className="data-details-title">
                        Transaction Status
                      </span>
                      {selectedTransaction?.status === "Confirmed" && (
                        <span className="badge badge-success ucap">
                          Confirmed
                        </span>
                      )}
                      {selectedTransaction?.status === "Rejected" && (
                        <span className="badge badge-danger ucap">
                          Rejected
                        </span>
                      )}
                      {selectedTransaction?.status === "Pending" && (
                        <span className="badge badge-warning ucap">
                          Pending
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="gaps-3x" />
                  <h6 className="card-sub-title">Transaction Info</h6>
                  <ul className="data-details-list">
                    <li>
                      <div className="data-details-head">Transaction Type</div>
                      <div className="data-details-des">
                        <strong>{selectedTransaction?.transactionType}</strong>
                      </div>
                    </li>
                    {/* li */}
                    <li>
                      <div className="data-details-head">Amount</div>
                      <div className="data-details-des">
                        <strong>
                          ${selectedTransaction?.amount?.toLocaleString()}
                        </strong>
                      </div>
                    </li>
                    {/* li */}
                    {selectedTransaction?.asset && (
                      <li>
                        <div className="data-details-head">Asset</div>
                        <div className="data-details-des">
                          <strong>{selectedTransaction?.asset}</strong>
                        </div>
                      </li>
                    )}
                    {/* li */}
                    <li>
                      <div className="data-details-head">Transaction ID</div>
                      <div className="data-details-des">
                        <span>#{selectedTransaction?.transactionId}</span>{" "}
                        <span />
                      </div>
                    </li>
                    {/* li */}
                    {selectedTransaction?.remarks && (
                      <li>
                        <div className="data-details-head">Remarks</div>
                        <div className="data-details-des">
                          <span>{selectedTransaction?.remarks}</span>
                          <span />
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                {/* .card */}
              </div>
            </div>
            {/* .modal-content */}
          </div>
          {/* .modal-dialog */}
        </div>
      )}
    </div>
  );
};

export default Transactions;
