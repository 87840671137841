import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import Preloader from "../components/Preloader";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Activity = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const user = useSelector((state: any) => state.user.currentUser);
  const [firstName] = user?.name.split(" ");

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getActivity = async () => {
      const { data } = await axios.get(
        `https://api.istratradesphere.com/api/activity/${user._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setActivities(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getActivity();
  }, [user._id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 main-content">
              <div className="card content-area">
                <div className="card-innr">
                  <div className="card-head">
                    <h4 className="card-title">Activity</h4>
                  </div>
                  <div className="card-text">
                    <p>Here is your recent activities log</p>
                  </div>
                  <div className="gaps-1-5x" />
                  <table
                    className="data-table dt-init activity-table"
                    data-items={10}
                  >
                    <thead>
                      <tr className="data-item data-head">
                        <th className="data-col activity-time">
                          <span>Date/Time</span>
                        </th>
                        <th className="data-col activity-device">
                          <span>Device</span>
                        </th>
                        <th className="data-col activity-browser">
                          <span>Browser</span>
                        </th>
                        <th className="data-col activity-ip">
                          <span>IP</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activities?.slice(0, 10)?.map((log: any) => (
                        <tr className="data-item" key={log._id}>
                          <td className="data-col activity-time">
                            {new Date(log.createdAt).toLocaleString()}
                          </td>
                          <td className="data-col activity-device">{log.os}</td>
                          <td className="data-col activity-browser">
                            {log.browser}
                          </td>
                          <td className="data-col activity-ip">{log.ip}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* .card-innr */}
              </div>
              {/* .card */}
            </div>
            <div className="col-lg-4 aside sidebar-right">
              <div className="account-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Your Account Status
                  </h6>
                  <ul className="btn-grp">
                    <li>
                      <a href="#/" className="btn btn-auto btn-xs btn-success">
                        Email Verified
                      </a>
                    </li>
                    <li>
                      {user.kycStatus === "Approved" ? (
                        <a
                          href="#/"
                          className="btn btn-auto btn-xs btn-success"
                        >
                          KYC Verified
                        </a>
                      ) : (
                        <a
                          href="#/"
                          className="btn btn-auto btn-xs btn-warning"
                        >
                          KYC Pending
                        </a>
                      )}
                    </li>
                  </ul>
                  <div className="gaps-2-5x" />
                </div>
              </div>
              <div className="referral-info card">
                <div className="card-innr">
                  <h6 className="card-title card-title-sm">
                    Earn with Referral
                  </h6>
                  <p className=" pdb-0-5x">
                    Invite your friends &amp; family and receive a{" "}
                    <strong>
                      <span className="text-primary">bonus</span> of the value
                      of their investment.
                    </strong>
                  </p>
                  <div className="copy-wrap mgb-0-5x">
                    <span className="copy-feedback" />
                    <input
                      type="text"
                      className="copy-address"
                      defaultValue={`https://dashboard.istratradesphere.com/register?ref=${firstName}${user?._id}`}
                      disabled
                    />
                    <button
                      className="copy-trigger copy-clipboard"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://dashboard.istratradesphere.com/register?ref=${firstName}${user?._id}`
                        );
                        toast.success("Referral link copied");
                      }}
                    >
                      <em className="ti ti-files" />
                    </button>
                  </div>
                </div>
              </div>
              {user.kycStatus !== "Approved" && (
                <div className="kyc-info card">
                  <div className="card-innr">
                    <h6 className="card-title card-title-sm">
                      Identity Verification - KYC
                    </h6>
                    <p>
                      To comply with regulation, investors will have to go
                      through identity verification.
                    </p>
                    <p className="lead text-light pdb-0-5x">
                      You have not submitted your KYC application to verify your
                      identity.
                    </p>
                    <Link
                      to="/kyc-application"
                      className="btn btn-primary btn-block"
                    >
                      Click to Proceed
                    </Link>
                    <h6 className="kyc-alert text-danger">
                      * KYC verification required for certain actions
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default Activity;
