import preloader from "../assets/favicon.png";

const Preloader = () => {
  return (
    <div className="preloader">
      <img src={preloader} alt="preloader" />
    </div>
  );
};

export default Preloader;
