import { Link } from "react-router-dom";
import logo from "../assets/favicon.png";

const Logo = () => {
  return (
    <div className="page-ath-header" style={{ fontWeight: "500" }}>
      <Link to="/" className="page-ath-logo">
        <img src={logo} alt="logo" />
      </Link>
      Istratradesphere
    </div>
  );
};

export default Logo;
