import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import axios from "axios";
import Pagination from "../../components/Pagination";
import Preloader from "../../components/Preloader";

const KycList = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [kycList, setKycList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForKyc, setSearchingForKyc] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [kycRecordId, setKycRecordId] = useState("");
  const [idToApprove, setIdtoApprove] = useState("");
  const [idToReject, setIdtoReject] = useState("");
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = kycList?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(kycList?.length / recordsPerPage);

  useEffect(() => {
    if (searchingForKyc) {
      const foundUser = kycList?.filter((user: any) => {
        return Object.values(user.firstName)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchTerm, kycList, searchingForKyc]);

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getKycList = async () => {
      const { data } = await axios.get(
        "https://api.istratradesphere.com/api/kyc",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setKycList(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getKycList();
  }, []);

  const approveKyc = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/kyc/approve",
        {
          idToApprove,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setApproveModalOpen(false);
      toast.success("KYC approved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const rejectKyc = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/kyc/reject",
        {
          idToReject,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRejectModalOpen(false);
      toast.success("KYC rejected");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="content-area card">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">KYC List</h4>
              </div>
              <div className="row justify-content-between pdb-1x">
                <div className="col-9 col-sm-6 text-left py-2">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <span className="input-icon input-icon-left ml-2.5">
                        <em className="ti ti-search absolute top-1/2 translate-y-[-50%]" />
                      </span>
                      <input
                        type="text"
                        style={{
                          border: "none",
                          paddingLeft: "18px",
                          outline: "none",
                        }}
                        placeholder="Search user by firstname"
                        onChange={(e) => {
                          setSearchingForKyc(true);
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {!searchTerm && kycList?.length > 0 && (
                <table className="data-table dt-init kyc-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-doc-type">Doc Type</th>
                      <th className="data-col dt-doc-front">Document</th>
                      <th className="data-col dt-doc-back">Submitted on</th>
                      <th className="data-col dt-status">Status</th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords?.map((record: any) => (
                      <tr className="data-item" key={record._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">
                            {record?.user?.name}
                          </span>
                          <span className="sub user-id">
                            {record?.user?.email}
                          </span>
                        </td>
                        <td className="data-col dt-doc-type">
                          <span className="sub sub-s2 sub-dtype">
                            {record.chosenId}
                          </span>
                        </td>
                        <td className="data-col dt-doc-front">
                          <a
                            href={record.document}
                            className="image-popup"
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        </td>
                        <td className="data-col dt-doc-back">
                          <span className="sub sub-s2 sub-dtype">
                            {new Date(record?.createdAt)?.toDateString()}
                          </span>
                        </td>
                        {record.status === "Approved" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              Approved
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              A
                            </span>
                          </td>
                        )}
                        {record.status === "Rejected" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              Rejected
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              R
                            </span>
                          </td>
                        )}
                        {record.status === "Pending" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              Pending
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              P
                            </span>
                          </td>
                        )}
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setKycRecordId((kycId: any) =>
                                  kycId === record._id ? "" : record._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {kycRecordId === record._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/kyc/${record._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {record.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(record._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {record.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoReject(record._id);
                                          setRejectModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Reject
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {searchTerm && searchResults?.length > 0 && (
                <table className="data-table dt-init kyc-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-doc-type">Doc Type</th>
                      <th className="data-col dt-doc-front">Document</th>
                      <th className="data-col dt-doc-back">Submitted on</th>
                      <th className="data-col dt-status">Status</th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults?.map((record: any) => (
                      <tr className="data-item" key={record._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">
                            {record?.user?.name}
                          </span>
                          <span className="sub user-id">
                            {record?.user?.email}
                          </span>
                        </td>
                        <td className="data-col dt-doc-type">
                          <span className="sub sub-s2 sub-dtype">
                            {record.chosenId}
                          </span>
                        </td>
                        <td className="data-col dt-doc-front">
                          <a
                            href={record.document}
                            className="image-popup"
                            target="_blank"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        </td>
                        <td className="data-col dt-doc-back">
                          <span className="sub sub-s2 sub-dtype">
                            {new Date(record?.createdAt)?.toDateString()}
                          </span>
                        </td>
                        {record.status === "Approved" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              Approved
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              A
                            </span>
                          </td>
                        )}
                        {record.status === "Rejected" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              Rejected
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              R
                            </span>
                          </td>
                        )}
                        {record.status === "Pending" && (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-warning badge-md">
                              Pending
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-warning badge-md">
                              P
                            </span>
                          </td>
                        )}
                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              onClick={() =>
                                setKycRecordId((kycId: any) =>
                                  kycId === record._id ? "" : record._id
                                )
                              }
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {kycRecordId === record._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/kyc/${record._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {record.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoApprove(record._id);
                                          setApproveModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-check" /> Approve
                                      </a>
                                    </li>
                                  )}
                                  {record.status === "Pending" && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdtoReject(record._id);
                                          setRejectModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Reject
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!searchTerm && kycList?.length === 0 && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No kyc records yet
                  </p>
                </div>
              )}

              {!searchTerm && kycList?.length > 20 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}

              {noResults && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No results match your search
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {approveModalOpen && <div className="modal-backdrop fade show" />}
      {approveModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setApproveModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to approve this kyc?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Approving...
                  </button>
                ) : (
                  <button onClick={approveKyc} className="btn btn-primary">
                    Yes, Approve
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this kyc?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button onClick={rejectKyc} className="btn btn-primary">
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KycList;
