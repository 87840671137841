import React, { useState, useRef, FormEventHandler, useEffect } from "react";
import { useSelector } from "react-redux";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";

const KycForm = () => {
  const [chosenId, setChosenId] = useState("Passport");
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [noSelectedFile, setNoSelectedFile] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [nationality, setNationality] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const inputRef: any = useRef(null);
  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    if (user?.kycStatus === "Pending" || user?.kycStatus === "Approved") {
      navigate("/kyc-completed");
    }
  }, [navigate, user?.kycStatus]);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setSelectedFile(e.dataTransfer.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setFileUploaded(true);
      setNoSelectedFile(false);
    } else {
      setSelectedFile(null);
      setNoSelectedFile(true);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const cancelUpload = () => {
    setFileUploaded(false);
    setSelectedFile(null);
  };

  const submitKyc: FormEventHandler = (e) => {
    e.preventDefault();

    if (!selectedFile) {
      setNoSelectedFile(true);
      return;
    } else {
      setNoSelectedFile(false);
    }

    const fileName = new Date().getTime() + selectedFile.name;
    const storage = getStorage(app);
    const storageRef = ref(storage, fileName);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    setLoading(true);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
        setLoading(false);
        toast.error("Something went wrong");
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const sendKyc = async () => {
            const { data } = await axios.post(
              "https://api.istratradesphere.com/api/kyc",
              {
                firstName,
                lastName,
                email,
                phone,
                dob,
                addressOne,
                addressTwo,
                city,
                state,
                nationality,
                zipCode,
                document: downloadURL,
                chosenId,
              }
            );

            if (data === "No user found") {
              toast.error("No user record found");
              setLoading(false);
              return;
            } else {
              setLoading(false);
              navigate("/kyc-thank-you");
            }
          };

          sendKyc();
        });
      }
    );
  };

  return (
    <div className="page-user">
      <TopBar />
      <div className="page-header page-header-kyc">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-7 text-center">
              <h2 className="page-title">Begin your ID-Verification</h2>
              <p className="large">Verify your identity</p>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-9">
              <form onSubmit={submitKyc}>
                <div className="kyc-form-steps card mx-lg-4">
                  <div className="form-step form-step1">
                    <div className="form-step-head card-innr">
                      <div className="step-head">
                        <div className="step-number">01</div>
                        <div className="step-head-text">
                          <h4>Personal Details</h4>
                          <p>
                            Your simple personal information required for
                            identification
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .step-head */}
                    <div className="form-step-fields card-innr">
                      <div className="note note-plane note-light-alt note-md pdb-1x">
                        <em className="fas fa-info-circle" />
                        <p>
                          Please type carefully and fill out the form with your
                          personal details. Your can’t edit these details once
                          you've submitted the form.
                        </p>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={firstName}
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Last Name <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={lastName}
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Email Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="email"
                              required
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                            <p className="mt-2" style={{ fontSize: "12px" }}>
                              Email address should be the same one used during
                              registration
                            </p>
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Phone Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <PhoneInput
                              className="input-bordered"
                              required
                              value={phone}
                              onChange={(number: any) => setPhone(number)}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Date of Birth{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered date-picker"
                              type="date"
                              required
                              value={dob}
                              onChange={(e) => {
                                setDob(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                      </div>
                      {/* .row */}
                      <h4 className="text-secondary mgt-0-5x">Your Address</h4>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Address Line 1{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={addressOne}
                              onChange={(e) => {
                                setAddressOne(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Address Line 2
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              value={addressTwo}
                              onChange={(e) => {
                                setAddressTwo(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              City
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              State
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label
                              htmlFor="nationality"
                              className="input-item-label"
                            >
                              Nationality <span className="text-danger">*</span>
                            </label>
                            <select
                              className="select-bordered select-block input-bordered"
                              name="nationality"
                              id="nationality"
                              required
                              value={nationality}
                              onChange={(e) => setNationality(e.target.value)}
                            >
                              <option value="">-</option>
                              <option value="Afganistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">
                                American Samoa
                              </option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua &amp; Barbuda">
                                Antigua &amp; Barbuda
                              </option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bonaire">Bonaire</option>
                              <option value="Bosnia &amp; Herzegovina">
                                Bosnia &amp; Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Ter">
                                British Indian Ocean Ter
                              </option>
                              <option value="Brunei">Brunei</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Canary Islands">
                                Canary Islands
                              </option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">
                                Cayman Islands
                              </option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Channel Islands">
                                Channel Islands
                              </option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">
                                Christmas Island
                              </option>
                              <option value="Cocos Island">Cocos Island</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote DIvoire">Cote DIvoire</option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Curaco">Curacao</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands">
                                Falkland Islands
                              </option>
                              <option value="Faroe Islands">
                                Faroe Islands
                              </option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">
                                French Guiana
                              </option>
                              <option value="French Polynesia">
                                French Polynesia
                              </option>
                              <option value="French Southern Ter">
                                French Southern Ter
                              </option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Great Britain">
                                Great Britain
                              </option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="India">India</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea North">Korea North</option>
                              <option value="Korea Sout">Korea South</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Laos">Laos</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libya">Libya</option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Midway Islands">
                                Midway Islands
                              </option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Nambia">Nambia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherland Antilles">
                                Netherland Antilles
                              </option>
                              <option value="Netherlands">
                                Netherlands (Holland, Europe)
                              </option>
                              <option value="Nevis">Nevis</option>
                              <option value="New Caledonia">
                                New Caledonia
                              </option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">
                                Norfolk Island
                              </option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau Island">Palau Island</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Phillipines">Philippines</option>
                              <option value="Pitcairn Island">
                                Pitcairn Island
                              </option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Republic of Montenegro">
                                Republic of Montenegro
                              </option>
                              <option value="Republic of Serbia">
                                Republic of Serbia
                              </option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="St Barthelemy">
                                St Barthelemy
                              </option>
                              <option value="St Eustatius">St Eustatius</option>
                              <option value="St Helena">St Helena</option>
                              <option value="St Kitts-Nevis">
                                St Kitts-Nevis
                              </option>
                              <option value="St Lucia">St Lucia</option>
                              <option value="St Maarten">St Maarten</option>
                              <option value="St Pierre &amp; Miquelon">
                                St Pierre &amp; Miquelon
                              </option>
                              <option value="St Vincent &amp; Grenadines">
                                St Vincent &amp; Grenadines
                              </option>
                              <option value="Saipan">Saipan</option>
                              <option value="Samoa">Samoa</option>
                              <option value="Samoa American">
                                Samoa American
                              </option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome &amp; Principe">
                                Sao Tome &amp; Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">Syria</option>
                              <option value="Tahiti">Tahiti</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad &amp; Tobago">
                                Trinidad &amp; Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks &amp; Caicos Is">
                                Turks &amp; Caicos Is
                              </option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Erimates">
                                United Arab Emirates
                              </option>
                              <option value="United States of America">
                                United States of America
                              </option>
                              <option value="Uraguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Vatican City State">
                                Vatican City State
                              </option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (Brit)">
                                Virgin Islands (Brit)
                              </option>
                              <option value="Virgin Islands (USA)">
                                Virgin Islands (USA)
                              </option>
                              <option value="Wake Island">Wake Island</option>
                              <option value="Wallis &amp; Futana Is">
                                Wallis &amp; Futana Is
                              </option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zaire">Zaire</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </select>
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                        <div className="col-md-6">
                          <div className="input-item input-with-label">
                            <label className="input-item-label">
                              Zip Code <span className="text-danger">*</span>
                            </label>
                            <input
                              className="input-bordered"
                              type="text"
                              required
                              value={zipCode}
                              onChange={(e) => {
                                setZipCode(e.target.value);
                              }}
                            />
                          </div>
                          {/* .input-item */}
                        </div>
                        {/* .col */}
                      </div>
                      {/* .row */}
                    </div>
                    {/* .step-fields */}
                  </div>
                  <div className="form-step form-step2">
                    <div className="form-step-head card-innr">
                      <div className="step-head">
                        <div className="step-number">02</div>
                        <div className="step-head-text">
                          <h4>Document Upload</h4>
                          <p>
                            To verify your identity, please upload any of your
                            documents
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .step-head */}
                    <div className="form-step-fields card-innr">
                      <div className="note note-plane note-light-alt note-md pdb-0-5x">
                        <em className="fas fa-info-circle" />
                        <p>
                          In order to complete, please upload any of the
                          following personal documents
                        </p>
                      </div>
                      <div className="gaps-2x" />
                      <ul
                        className="nav nav-tabs nav-tabs-bordered row flex-wrap guttar-20px"
                        role="tablist"
                      >
                        <li className="nav-item flex-grow-0">
                          <a
                            className="nav-link d-flex align-items-center active"
                            data-toggle="tab"
                            href="#passport"
                            onClick={() => setChosenId("Passport")}
                          >
                            <div className="nav-tabs-icon">
                              <img src="images/icon-passport.png" alt="icon" />
                              <img
                                src="images/icon-passport-color.png"
                                alt="icon"
                              />
                            </div>
                            <span>Passport</span>
                          </a>
                        </li>
                        <li className="nav-item flex-grow-0">
                          <a
                            className="nav-link d-flex align-items-center"
                            data-toggle="tab"
                            href="#national-card"
                            onClick={() => setChosenId("National ID")}
                          >
                            <div className="nav-tabs-icon">
                              <img
                                src="images/icon-national-id.png"
                                alt="icon"
                              />
                              <img
                                src="images/icon-national-id-color.png"
                                alt="icon"
                              />
                            </div>
                            <span>National ID</span>
                          </a>
                        </li>
                        <li className="nav-item flex-grow-0">
                          <a
                            className="nav-link d-flex align-items-center"
                            data-toggle="tab"
                            href="#driver-licence"
                            onClick={() => setChosenId("Driver's License")}
                          >
                            <div className="nav-tabs-icon">
                              <img src="images/icon-licence.png" alt="icon" />
                              <img
                                src="images/icon-licence-color.png"
                                alt="icon"
                              />
                            </div>
                            <span>Driver’s License</span>
                          </a>
                        </li>
                      </ul>
                      {/* .nav-tabs-line */}
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="passport"
                        >
                          <h5 className="text-secondary font-bold">
                            To avoid delays when verifying account, Please make
                            sure below:
                          </h5>
                          <ul className="list-check">
                            <li>Chosen credential must not be expired</li>
                            <li>
                              Document should be in good condition and clearly
                              visible
                            </li>
                            <li>
                              Make sure that there is no light glare on the
                              document
                            </li>
                          </ul>
                          <div className="gaps-2x" />
                          <h5 className="font-mid">
                            Upload Here Your Passport Copy
                          </h5>
                          <div className="row align-items-center">
                            <div className="col-sm-8">
                              <div
                                className="upload-box"
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  {fileUploaded ? (
                                    <div className="dz-message" data-dz-message>
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text mb-3 text-base">
                                          {`${selectedFile?.name.substring(
                                            0,
                                            20
                                          )}...`}
                                        </span>
                                        <button
                                          className="btn btn-primary bg-[#2c80ff]"
                                          type="button"
                                          onClick={cancelUpload}
                                        >
                                          CANCEL
                                        </button>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={onButtonClick}
                                        >
                                          SELECT
                                        </button>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {dragActive && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      border: "4px",
                                      top: "0",
                                      bottom: "0",
                                      right: "0",
                                      left: "0",
                                    }}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 d-none d-sm-block">
                              <div className="mx-md-4">
                                <img
                                  src="images/vector-passport.png"
                                  alt="vector"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .tab-pane */}
                        <div className="tab-pane fade" id="national-card">
                          <h5 className="text-secondary font-bold">
                            To avoid delays when verifying account, Please make
                            sure below:
                          </h5>
                          <ul className="list-check">
                            <li>Chosen credential must not be expired</li>
                            <li>
                              Document should be in good condition and clearly
                              visible
                            </li>
                            <li>
                              Make sure that there is no light glare on the
                              document
                            </li>
                          </ul>
                          <div className="gaps-2x" />
                          <h5 className="font-mid">
                            Upload Here Your National ID Copy
                          </h5>
                          <div className="row align-items-center">
                            <div className="col-sm-8">
                              <div
                                className="upload-box"
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  {fileUploaded ? (
                                    <div className="dz-message" data-dz-message>
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text mb-3 text-base">
                                          {`${selectedFile?.name.substring(
                                            0,
                                            20
                                          )}...`}
                                        </span>
                                        <button
                                          className="btn btn-primary bg-[#2c80ff]"
                                          type="button"
                                          onClick={cancelUpload}
                                        >
                                          CANCEL
                                        </button>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={onButtonClick}
                                        >
                                          SELECT
                                        </button>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {dragActive && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      border: "4px",
                                      top: "0",
                                      bottom: "0",
                                      right: "0",
                                      left: "0",
                                    }}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 d-none d-sm-block">
                              <div className="mx-md-4">
                                <img
                                  src="images/vector-passport.png"
                                  alt="vector"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .tab-pane */}
                        <div className="tab-pane fade" id="driver-licence">
                          <h5 className="text-secondary font-bold">
                            To avoid delays when verifying account, Please make
                            sure below:
                          </h5>
                          <ul className="list-check">
                            <li>Chosen credential must not be expired</li>
                            <li>
                              Document should be in good condition and clearly
                              visible
                            </li>
                            <li>
                              Make sure that there is no light glare on the
                              document
                            </li>
                          </ul>
                          <div className="gaps-2x" />
                          <h5 className="font-mid">
                            Upload Here Your Driver's License Copy
                          </h5>
                          <div className="row align-items-center">
                            <div className="col-sm-8">
                              <div
                                className="upload-box"
                                onDragEnter={handleDrag}
                              >
                                <div className="upload-zone dropzone dz-clickable">
                                  <input
                                    ref={inputRef}
                                    type="file"
                                    id="input-file-upload"
                                    style={{ display: "none" }}
                                    onChange={handleChange}
                                    accept="image/*"
                                  />
                                  {fileUploaded ? (
                                    <div className="dz-message" data-dz-message>
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text mb-3 text-base">
                                          {`${selectedFile?.name.substring(
                                            0,
                                            20
                                          )}...`}
                                        </span>
                                        <button
                                          className="btn btn-primary bg-[#2c80ff]"
                                          type="button"
                                          onClick={cancelUpload}
                                        >
                                          CANCEL
                                        </button>
                                      </label>
                                    </div>
                                  ) : (
                                    <div
                                      className="dz-message"
                                      data-dz-message="true"
                                    >
                                      <label htmlFor="input-file-upload">
                                        <span className="dz-message-text">
                                          Drag and drop file
                                        </span>
                                        <span className="dz-message-or">
                                          or
                                        </span>
                                        <button
                                          className="btn btn-primary bg-green"
                                          type="button"
                                          onClick={onButtonClick}
                                        >
                                          SELECT
                                        </button>
                                      </label>
                                    </div>
                                  )}
                                </div>
                                {dragActive && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      width: "100%",
                                      height: "100%",
                                      border: "4px",
                                      top: "0",
                                      bottom: "0",
                                      right: "0",
                                      left: "0",
                                    }}
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-4 d-none d-sm-block">
                              <div className="mx-md-4">
                                <img
                                  src="images/vector-passport.png"
                                  alt="vector"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* .tab-pane */}
                      </div>
                      {/* .tab-content */}
                      <div
                        className="pay-notes"
                        style={{ border: "none", padding: "0" }}
                      >
                        <div className="note note-plane note-light note-md font-italic">
                          <em className="fas fa-info-circle" />
                          <p>Uploaded file should be less than 3MB.</p>
                        </div>
                      </div>
                      {noSelectedFile && (
                        <p className="mt-3 text-danger text-sm">
                          Please upload a document
                        </p>
                      )}
                      {/* .step-fields */}
                    </div>
                  </div>

                  <div className="form-step form-step-final">
                    <div className="form-step-fields card-innr">
                      <div className="input-item">
                        <input
                          className="input-checkbox input-checkbox-md"
                          id="term-condition"
                          type="checkbox"
                          required
                        />
                        <label htmlFor="term-condition">
                          I have read the
                          <Link to="/privacy-policy">
                            Terms & Conditions
                          </Link>{" "}
                          and <Link to="/privacy-policy">Privary Policy.</Link>
                        </label>
                      </div>
                      <div className="input-item">
                        <input
                          className="input-checkbox input-checkbox-md"
                          id="info-currect"
                          type="checkbox"
                          required
                        />
                        <label htmlFor="info-currect">
                          All the personal information I have entered is
                          correct.
                        </label>
                      </div>
                      <div className="gaps-1x" />
                      {loading ? (
                        <button
                          className="btn btn-primary"
                          type="button"
                          disabled
                        >
                          Submitting...
                        </button>
                      ) : (
                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                    {/* .step-fields */}
                  </div>
                </div>
              </form>
              {/* .card */}
            </div>
          </div>
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </div>
  );
};

export default KycForm;
