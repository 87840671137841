import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

const EmailVerified = () => {
  return (
    <div className="page-ath">
      <div className="page-ath-wrap">
        <div className="page-ath-content">
          <Logo />
          <div className="page-ath-text">
            <div className="alert alert-success">
              Your email is successfully verified.
            </div>
            <div className="gaps-0-5x" />
            <Link to="/login" className="btn btn-primary">
              Sign in
            </Link>
          </div>
          <div className="page-ath-footer">
            <ul className="footer-links">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Terms</Link>
              </li>
              <li>© {new Date().getFullYear()} Istratradesphere.</li>
            </ul>
          </div>
        </div>
        <div className="page-ath-gfx">
          <div className="w-100 d-flex justify-content-center">
            <div className="col-md-8 col-xl-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
