import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import axios from "axios";
import Preloader from "../../components/Preloader";
import Pagination from "../../components/Pagination";

const Users = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchingForUser, setSearchingForUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [userRecordId, setUserRecordId] = useState("");
  const [idToSuspend, setIdToSuspend] = useState("");
  const [idToUnsuspend, setIdToUnsuspend] = useState("");
  const [idToVerify, setIdToVerify] = useState("");
  const [suspendModalOpen, setSuspendModalOpen] = useState(false);
  const [unsuspendModalOpen, setUnsuspendModalOpen] = useState(false);
  const [verifyModalOpen, setVerifyModalOpen] = useState(false);

  const navigate = useNavigate();

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = users?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(users?.length / recordsPerPage);

  useEffect(() => {
    if (searchingForUser) {
      const foundUser = users?.filter((user: any) => {
        return Object.values(user.name)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResults(foundUser);

      if (foundUser.length === 0) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    }
  }, [searchingForUser, searchTerm, users]);

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getUsers = async () => {
      const { data } = await axios.get(
        "https://api.istratradesphere.com/api/user/find",
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setUsers(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getUsers();
  }, []);

  const suspendUser = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/user/suspend",
        { idToSuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setSuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const unsuspendUser = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/user/unsuspend",
        { idToUnsuspend },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setUnsuspendModalOpen(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const verifyEmail = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        `https://api.istratradesphere.com/api/user/verify/${idToVerify}`,
        { verified: true },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      navigate(0);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head">
                <h4 className="card-title">User List</h4>
              </div>
              <div className="row justify-content-between pdb-1x">
                <div className="col-9 col-sm-6 text-left py-2">
                  <div
                    id="DataTables_Table_0_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <span className="input-icon input-icon-left ml-2.5">
                        <em className="ti ti-search absolute top-1/2 translate-y-[-50%]" />
                      </span>
                      <input
                        type="text"
                        style={{
                          border: "none",
                          paddingLeft: "20px",
                          outline: "none",
                        }}
                        placeholder="Search user by name"
                        onChange={(e) => {
                          setSearchingForUser(true);
                          setSearchTerm(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              {!searchTerm && users?.length > 0 && (
                <table className="data-table dt-init user-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-email">Phone</th>
                      <th className="data-col dt-email">Plan</th>
                      <th className="data-col dt-token">Balance</th>
                      <th className="data-col dt-verify">Verified Status</th>
                      <th className="data-col dt-status">
                        <div className="dt-status-text">Status</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {currentRecords?.map((user: any) => (
                      <tr className="data-item" key={user._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">{user.name}</span>
                          <span className="sub user-id">{user.email}</span>
                        </td>
                        <td className="data-col dt-email">
                          <span className="sub sub-s2 sub-email">
                            {user.phone}
                          </span>
                        </td>
                        <td className="data-col dt-email">
                          {user?.deposit <= 99 && (
                            <span className="sub sub-s2 sub-email">Free</span>
                          )}
                          {user?.deposit >= 100 && user?.deposit <= 50000 && (
                            <span className="sub sub-s2 sub-email">Golden</span>
                          )}
                          {user?.deposit >= 50001 &&
                            user?.deposit <= 100000 && (
                              <span className="sub sub-s2 sub-email">
                                Sterling
                              </span>
                            )}
                          {user?.deposit >= 100001 &&
                            user?.deposit <= 1000000 && (
                              <span className="sub sub-s2 sub-email">
                                Premium
                              </span>
                            )}
                          {user?.deposit >= 1000001 && (
                            <span className="sub sub-s2 sub-email">
                              Platinum
                            </span>
                          )}
                        </td>
                        <td className="data-col dt-token">
                          <span className="lead lead-btoken">
                            ${user.balance?.toLocaleString()}
                          </span>
                        </td>
                        <td className="data-col dt-verify">
                          <ul className="data-vr-list">
                            {user.verified ? (
                              <li>
                                <div className="data-state data-state-sm data-state-approved" />{" "}
                                Email
                              </li>
                            ) : (
                              <li>
                                <div className="data-state data-state-sm data-state-pending" />{" "}
                                Email
                              </li>
                            )}

                            {user.kycStatus === "Approved" && (
                              <li>
                                <div className="data-state data-state-sm data-state-approved" />{" "}
                                KYC
                              </li>
                            )}
                            {user.kycStatus === "Rejected" && (
                              <li>
                                <div className="data-state data-state-sm data-state-canceled" />{" "}
                                KYC
                              </li>
                            )}
                            {(user.kycStatus === "Unverified" ||
                              user.kycStatus === "Pending") && (
                              <li>
                                <div className="data-state data-state-sm data-state-pending" />{" "}
                                KYC
                              </li>
                            )}
                          </ul>
                        </td>

                        {user.disabled ? (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              Suspended
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              S
                            </span>
                          </td>
                        ) : (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              Active
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              A
                            </span>
                          </td>
                        )}

                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              onClick={() =>
                                setUserRecordId((userRecordId: string) =>
                                  userRecordId === user?._id ? "" : user?._id
                                )
                              }
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {userRecordId === user?._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/user/${user?._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {user.disabled ? (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToUnsuspend(user._id);
                                          setUnsuspendModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Unsuspend
                                      </a>
                                    </li>
                                  ) : (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToSuspend(user._id);
                                          setSuspendModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Suspend
                                      </a>
                                    </li>
                                  )}
                                  {!user.verified && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToVerify(user._id);
                                          setVerifyModalOpen(true);
                                        }}
                                      >
                                        Verify Email
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {searchTerm && searchResults.length > 0 && (
                <table className="data-table dt-init user-list">
                  <thead>
                    <tr className="data-item data-head">
                      <th className="data-col dt-user">User</th>
                      <th className="data-col dt-email">Phone</th>
                      <th className="data-col dt-email">Plan</th>
                      <th className="data-col dt-token">Balance</th>
                      <th className="data-col dt-verify">Verified Status</th>
                      <th className="data-col dt-status">
                        <div className="dt-status-text">Status</div>
                      </th>
                      <th className="data-col" />
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults?.map((user: any) => (
                      <tr className="data-item" key={user._id}>
                        <td className="data-col dt-user">
                          <span className="lead user-name">{user.name}</span>
                          <span className="sub user-id">{user.email}</span>
                        </td>
                        <td className="data-col dt-email">
                          <span className="sub sub-s2 sub-email">
                            {user.phone}
                          </span>
                        </td>
                        <td className="data-col dt-email">
                          {user?.deposit <= 99 && (
                            <span className="sub sub-s2 sub-email">Free</span>
                          )}
                          {user?.deposit >= 100 && user?.deposit <= 50000 && (
                            <span className="sub sub-s2 sub-email">Golden</span>
                          )}
                          {user?.deposit >= 50001 &&
                            user?.deposit <= 100000 && (
                              <span className="sub sub-s2 sub-email">
                                Sterling
                              </span>
                            )}
                          {user?.deposit >= 100001 &&
                            user?.deposit <= 1000000 && (
                              <span className="sub sub-s2 sub-email">
                                Premium
                              </span>
                            )}
                          {user?.deposit >= 1000001 && (
                            <span className="sub sub-s2 sub-email">
                              Platinum
                            </span>
                          )}
                        </td>

                        <td className="data-col dt-token">
                          <span className="lead lead-btoken">
                            ${user.balance?.toLocaleString()}
                          </span>
                        </td>
                        <td className="data-col dt-verify">
                          <ul className="data-vr-list">
                            {user.verified ? (
                              <li>
                                <div className="data-state data-state-sm data-state-approved" />{" "}
                                Email
                              </li>
                            ) : (
                              <li>
                                <div className="data-state data-state-sm data-state-pending" />{" "}
                                Email
                              </li>
                            )}
                            {user.kycStatus === "Approved" && (
                              <li>
                                <div className="data-state data-state-sm data-state-approved" />{" "}
                                KYC
                              </li>
                            )}
                            {user.kycStatus === "Rejected" && (
                              <li>
                                <div className="data-state data-state-sm data-state-canceled" />{" "}
                                KYC
                              </li>
                            )}
                            {(user.kycStatus === "Unverified" ||
                              user.kycStatus === "Pending") && (
                              <li>
                                <div className="data-state data-state-sm data-state-pending" />{" "}
                                KYC
                              </li>
                            )}
                          </ul>
                        </td>

                        {user.disabled ? (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-danger badge-md">
                              Suspended
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-danger badge-md">
                              S
                            </span>
                          </td>
                        ) : (
                          <td className="data-col dt-status">
                            <span className="dt-status-md badge badge-outline badge-success badge-md">
                              Active
                            </span>
                            <span className="dt-status-sm badge badge-sq badge-outline badge-success badge-md">
                              A
                            </span>
                          </td>
                        )}

                        <td className="data-col text-right">
                          <div className="relative d-inline-block">
                            <button
                              className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                              onClick={() =>
                                setUserRecordId((userRecordId: string) =>
                                  userRecordId === user?._id ? "" : user?._id
                                )
                              }
                            >
                              <em className="ti ti-more-alt" />
                            </button>
                            {userRecordId === user?._id && (
                              <div className="toggle-class dropdown-content dropdown-content-top-left active">
                                <ul className="dropdown-list">
                                  <li>
                                    <Link to={`/user/${user?._id}`}>
                                      <em className="ti ti-eye" /> View Details
                                    </Link>
                                  </li>
                                  {user.disabled ? (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToUnsuspend(user._id);
                                          setUnsuspendModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Unsuspend
                                      </a>
                                    </li>
                                  ) : (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToSuspend(user._id);
                                          setSuspendModalOpen(true);
                                        }}
                                      >
                                        <em className="ti ti-na" /> Suspend
                                      </a>
                                    </li>
                                  )}
                                  {!user.verified && (
                                    <li>
                                      <a
                                        href="#/"
                                        onClick={() => {
                                          setIdToVerify(user._id);
                                          setVerifyModalOpen(true);
                                        }}
                                      >
                                        Verify Email
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {!searchTerm && users?.length === 0 && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No user records found
                  </p>
                </div>
              )}

              {!searchTerm && users?.length > 20 && (
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}

              {noResults && (
                <div className="h-[472px]">
                  <p className="text-center text-xl relative top-1/2 translate-y-[-50%]">
                    No results match your search
                  </p>
                </div>
              )}
            </div>
            {/* .card-innr */}
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {suspendModalOpen && <div className="modal-backdrop fade show" />}
      {suspendModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setSuspendModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to suspend this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Suspending...
                  </button>
                ) : (
                  <button onClick={suspendUser} className="btn btn-primary">
                    Yes, Suspend
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {unsuspendModalOpen && <div className="modal-backdrop fade show" />}
      {unsuspendModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setUnsuspendModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to unsuspend this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Unsuspending...
                  </button>
                ) : (
                  <button onClick={unsuspendUser} className="btn btn-primary">
                    Yes, Unsuspend
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {verifyModalOpen && <div className="modal-backdrop fade show" />}
      {verifyModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setVerifyModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to verify this user?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Verifying...
                  </button>
                ) : (
                  <button onClick={verifyEmail} className="btn btn-primary">
                    Yes, Verify
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
