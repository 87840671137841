import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Preloader from "../../components/Preloader";
import Footer from "../../components/Footer";
import axios from "axios";

const MessageDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [message, setMessage] = useState<any>({});

  const { id } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getMessageDetails = async () => {
      const { data } = await axios.get(
        `https://api.istratradesphere.com/api/message/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setMessage(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getMessageDetails();
  }, [id]);

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px]">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">Message Details</h4>
                <div className="d-flex align-items-center guttar-20px">
                  <div className="flex-col d-sm-block d-none">
                    <Link
                      to="/messages"
                      className="btn btn-sm btn-auto btn-primary"
                    >
                      <em className="fas fa-arrow-left mr-3" />
                      Back
                    </Link>
                  </div>
                  <div className="flex-col d-sm-none">
                    <Link
                      to="/messages"
                      className="btn btn-icon btn-sm btn-primary"
                    >
                      <em className="fas fa-arrow-left" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex flex-wrap align-items-center justify-content-between">
                <div className="fake-class">
                  <span className="data-details-title">Sent On</span>
                  <span className="data-details-info">
                    {new Date(message?.createdAt).toLocaleString()}
                  </span>
                </div>

                <div className="gaps-2x w-100 d-none d-md-block" />
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Message Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">Name</div>
                  <div className="data-details-des">{message?.name}</div>
                </li>
                <li>
                  <div className="data-details-head">Email Address</div>
                  <div className="data-details-des">{message?.email}</div>
                </li>
              </ul>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Message</h6>
              <ul className="data-details-list">
                <textarea
                  value={message?.message}
                  className="w-full border-0 outline-0 px-3 py-2 h-48"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MessageDetails;
