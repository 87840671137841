// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAMMH-SGl0KYDOLQq-VmeTDEwQ6ghF8td4",
  authDomain: "istra-c62ee.firebaseapp.com",
  projectId: "istra-c62ee",
  storageBucket: "istra-c62ee.appspot.com",
  messagingSenderId: "783050435385",
  appId: "1:783050435385:web:6384bf0ed683b5aa07d6e8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
