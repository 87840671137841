import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import Preloader from "../../components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const KycDetails = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [kycDetails, setKycDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const toggleOptions = () => {
    setOptionsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const token = localStorage.getItem("istra");

    const getKycDetails = async () => {
      const { data } = await axios.get(
        `https://api.istratradesphere.com/api/kyc/${id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setKycDetails(data);
      setTimeout(() => {
        setPageLoading(false);
      }, 500);
    };

    getKycDetails();
  }, [id]);

  const approveKyc = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/kyc/approve",
        {
          idToApprove: id,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setApproveModalOpen(false);
      toast.success("KYC approved");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const rejectKyc = async () => {
    const token = localStorage.getItem("istra");
    setLoading(true);

    try {
      await axios.put(
        "https://api.istratradesphere.com/api/kyc/reject",
        {
          idToReject: id,
        },
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setLoading(false);
      setRejectModalOpen(false);
      toast.success("KYC rejected");
      setTimeout(() => {
        navigate(0);
      }, 1800);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="page-user">
      {pageLoading && <Preloader />}
      <TopBar />
      <div className="page-content">
        <div className="container">
          <div className="card content-area">
            <div className="card-innr">
              <div className="card-head d-flex justify-content-between align-items-center">
                <h4 className="card-title mb-0">KYC Details</h4>
                <div className="d-flex align-items-center guttar-20px">
                  <div className="flex-col d-sm-block d-none">
                    <Link
                      to="/kyc-list"
                      className="btn btn-sm btn-auto btn-primary"
                    >
                      <em className="fas fa-arrow-left mr-3" />
                      Back
                    </Link>
                  </div>
                  <div className="flex-col d-sm-none">
                    <Link
                      to="/kyc-list"
                      className="btn btn-icon btn-sm btn-primary"
                    >
                      <em className="fas fa-arrow-left" />
                    </Link>
                  </div>
                  {kycDetails.status === "Pending" && (
                    <div className="relative d-inline-block">
                      <button
                        onClick={toggleOptions}
                        className="btn btn-dark btn-sm btn-icon toggle-tigger"
                      >
                        <em className="ti ti-more-alt" />
                      </button>
                      {optionsOpen && (
                        <div className="toggle-class dropdown-content dropdown-content-top-left active">
                          <ul className="dropdown-list">
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setApproveModalOpen(true);
                                }}
                              >
                                <em className="ti ti-check" /> Approve
                              </a>
                            </li>
                            <li>
                              <a
                                href="#/"
                                onClick={() => {
                                  setRejectModalOpen(true);
                                }}
                              >
                                <em className="ti ti-na" /> Reject
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="gaps-1-5x" />
              <div className="data-details d-md-flex flex-wrap align-items-center justify-content-between">
                <div className="fake-class">
                  <span className="data-details-title">Submited By</span>
                  <span className="data-details-info">
                    {kycDetails?.user?.name}
                  </span>
                </div>
                <div className="fake-class">
                  <span className="data-details-title">Submited On</span>
                  <span className="data-details-info">
                    {new Date(kycDetails?.createdAt).toLocaleString()}
                  </span>
                </div>
                <div className="fake-class">
                  {kycDetails.status === "Approved" && (
                    <span className="badge badge-md badge-block badge-success ucap">
                      Approved
                    </span>
                  )}
                  {kycDetails.status === "Rejected" && (
                    <span className="badge badge-md badge-block badge-danger ucap">
                      Rejected
                    </span>
                  )}
                  {kycDetails.status === "Pending" && (
                    <span className="badge badge-md badge-block badge-warning ucap">
                      Pending
                    </span>
                  )}
                </div>
              </div>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Personal Information</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">First Name</div>
                  <div className="data-details-des">
                    {kycDetails?.firstName}
                  </div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Last Name</div>
                  <div className="data-details-des">{kycDetails?.lastName}</div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Email Address</div>
                  <div className="data-details-des">{kycDetails?.email}</div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Phone Number</div>
                  <div className="data-details-des">{kycDetails?.phone}</div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Date of Birth</div>
                  <div className="data-details-des">
                    {new Date(kycDetails?.dob).toLocaleDateString()}
                  </div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Full Address</div>
                  <div className="data-details-des">
                    {`${kycDetails?.addressOne}, ${kycDetails?.addressTwo}, ${kycDetails?.city}, ${kycDetails?.state}, ${kycDetails?.zipCode}`}
                  </div>
                </li>
                {/* li */}
                <li>
                  <div className="data-details-head">Nationality</div>
                  <div className="data-details-des">
                    {kycDetails?.nationality}
                  </div>
                </li>
              </ul>
              <div className="gaps-3x" />
              <h6 className="card-sub-title">Uploaded Documnet</h6>
              <ul className="data-details-list">
                <li>
                  <div className="data-details-head">
                    {kycDetails?.chosenId}
                  </div>
                  <ul className="data-details-docs">
                    <li>
                      <div className="data-doc-item data-doc-item-lg">
                        <div className="data-doc-image">
                          <img src={kycDetails?.document} alt="document" />
                        </div>
                        <ul className="data-doc-actions">
                          <li>
                            <a
                              href={kycDetails?.document}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <em className="ti ti-import" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
                {/* li */}
              </ul>
            </div>
            {/* .card-innr */}
          </div>
          {/* .card */}
        </div>
        {/* .container */}
      </div>

      <Footer />

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />

      {approveModalOpen && <div className="modal-backdrop fade show" />}
      {approveModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setApproveModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to approve this kyc?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Approving...
                  </button>
                ) : (
                  <button onClick={approveKyc} className="btn btn-primary">
                    Yes, Approve
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}

      {rejectModalOpen && <div className="modal-backdrop fade show" />}
      {rejectModalOpen && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-md modal-dialog-centered">
            <div className="modal-content">
              <button
                style={{ border: "none", background: "transparent" }}
                onClick={() => setRejectModalOpen(false)}
                className="modal-close"
              >
                <em className="ti ti-close" />
              </button>
              <div className="popup-body text-center">
                <div className="gaps-2x" />
                <div className="gaps-2x" />
                <h4 className="popup-title pr-0">
                  Are you sure you want to reject this kyc?
                </h4>
                <p>This action cannot be undone.</p>
                <div className="gaps-2x" />
                {loading ? (
                  <button className="btn btn-primary" disabled>
                    Rejecting...
                  </button>
                ) : (
                  <button onClick={rejectKyc} className="btn btn-primary">
                    Yes, Reject
                  </button>
                )}
                <div className="gaps-1x" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default KycDetails;
